<template>
  <v-container>
    <v-text-field label="Title" v-model="eventTitle"></v-text-field>

    <v-autocomplete
      v-model="chosenPeople"
      :items="peopleList"
      multiple
      label="People"
    ></v-autocomplete>
    <div class="d-flex justify-center">
      <v-date-picker v-model="eventDate"></v-date-picker>
    </div>
    <div class="d-flex justify-center">
      <v-btn
        fab
        @click="saveEvent()"
        color="primary"
        class="ma-3"
        :disabled="eventTitle == '' && eventDate != null"
      >
        <v-icon dark>mdi-content-save-all</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import firebase from "firebase";
import "firebase/firestore";

export default {
  data: () => ({
    peopleList: ["Floris", "Robbert", "Tom M", "Tom R", "Lisa"],
    chosenPeople: [],
    eventTitle: "",
    eventDate: null,
  }),
  mounted: function () {
    this.getCurrentDate();
  },
  methods: {
    getCurrentDate() {
      let date = new Date();
      this.eventDate = moment(date).format("YYYY-MM-DD");
    },
    saveEvent() {
      var _self = this;
      let chosenDate = moment(_self.eventDate).format("DD-MM-YYYY");
      let date = new Date(moment(_self.eventDate).unix() * 1000);

      let eventData = {};

      if (_self.chosenPeople.length == 0) {
        eventData = { title: _self.eventTitle, dateTime: date };
      } else {
        eventData = {
          title: _self.eventTitle,
          people: _self.chosenPeople,
          dateTime: date,
        };
      }

      firebase
        .firestore()
        .collection("extras")
        .doc("/events/eventDetails/" + chosenDate)
        .set(eventData, { merge: true });

      this.$router.push("/home");
    },
  },
};
</script>

<style></style>
